export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function time5toTime(oadate) {
  var d = new Date();
  d.setTime(
    Math.round(oadate * 24 * 60 * 60 * 1000) + Date.parse("1899-12-30")
  );
  return d;
}

// 获取默认当前做账月份 格式2019-02 例如当前日期2019-03-02 => 2019-02
export function currentAccountPeriod() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month; // + seperator1 + strDate;
  let y = currentdate.split("-")[0];
  let m = currentdate.split("-")[1];
  if (m == "01") {
    return String(Number(y) - 1) + "12";
  } else {
    let x = Number(m) - 1;
    if (x < 10) {
      x = "0" + String(x);
    }
    return String(y) + x;
  }
}

export function saveBig(money) {
  let statusFu = false;
  if (money < 0) {
    money = -money;
    statusFu = true;
  }
  //汉字的数字
  var cnNums = new Array(
    "零",
    "壹",
    "贰",
    "叁",
    "肆",
    "伍",
    "陆",
    "柒",
    "捌",
    "玖"
  );
  //基本单位
  var cnIntRadice = new Array("", "拾", "佰", "仟");
  //对应整数部分扩展单位
  var cnIntUnits = new Array("", "万", "亿", "兆");
  //对应小数部分单位
  var cnDecUnits = new Array("角", "分", "毫", "厘");
  //整数金额时后面跟的字符
  var cnInteger = "整";
  //整型完以后的单位
  var cnIntLast = "圆";
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = "";
  //分离金额后用的数组，预定义
  var parts;
  if (money == "") {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return "";
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf(".") == -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != "") {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == "") {
    chineseStr += cnInteger;
  }

  if (statusFu) {
    chineseStr = "负" + chineseStr;
  }

  return chineseStr;
}

export function getLastDay(period) {
  let s = period.slice(0, 4) + "-" + period.slice(4, 6);
  var endDate = new Date(s);
  var currentMonth = endDate.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var lastTime = new Date(nextMonthFirstDay - oneDay);
  var endMonth = parseInt(lastTime.getMonth() + 1);
  var endDay = lastTime.getDate();
  if (endMonth < 10) {
    endMonth = "0" + endMonth;
  }
  if (endDay < 10) {
    endDay = "0" + endDay;
  }
  return endDate.getFullYear() + "-" + endMonth + "-" + endDay;
}

export function getHeight(height) {
  return window.innerHeight - height + "px";
}

export function copy(obj) {
  if (!obj) {
    return;
  }
  var newobj = obj.constructor === Array ? [] : {};
  if (typeof obj !== "object") {
    return;
  }
  for (var i in obj) {
    newobj[i] = typeof obj[i] === "object" ? copy(obj[i]) : obj[i];
  }
  return newobj;
}

//发票采集权限
export function invoiceCollection(disCode) {
  let data = {
    inPt: false, //增值税发票服务平台
    inSj: false, //地区电子税局系统
    outPt: false, //增值税发票服务平台
    outSj: false, //地区电子税局系统
  };
  if (disCode.match("3702")) {
    //青岛
  } else if (disCode.match("2102")) {
    //大连 todo
  } else if (disCode.match("3302")) {
    //宁波 todo
  } else if (disCode.match("2102")) {
    //大连 todo
  } else if (disCode.substring(0, 2) == "45") {
    //广西 todo
  } else if (disCode.substring(0, 2) == "64") {
    //宁夏 todo
  } else if (disCode.substring(0, 2) == "65") {
    //新疆 todo
  } else if (disCode.substring(0, 2) == "22") {
    //吉林 todo
  } else if (disCode.substring(0, 2) == "23") {
    //黑龙江 todo
  } else if (disCode.substring(0, 2) == "33") {
    //浙江 todo
  } else if (disCode.substring(0, 2) == "35") {
    //福建 todo
  } else if (disCode.substring(0, 2) == "36") {
    //江西 todo
  } else if (disCode.substring(0, 2) == "62") {
    //甘肃 todo
  } else if (disCode.substring(0, 2) == "37") {
    //山东
  } else if (disCode.substring(0, 4).match("3101")) {
    //上海
  } else if (disCode.substring(0, 2) == "15") {
    //内蒙
  } else if (disCode.substring(0, 2) == "11") {
    //北京
  } else if (disCode.substring(0, 2) == "41") {
    //河南
  } else if (disCode.substring(0, 2) == "32") {
    //江苏
  } else if (disCode.substring(0, 2) == "14") {
    //山西
  } else if (disCode.substring(0, 2) == "51") {
    //四川
  } else if (disCode.substring(0, 2) == "12") {
    //天津
  } else if (disCode.substring(0, 2) == "61") {
    //陕西
  } else if (disCode.substring(0, 2) == "52") {
    //贵州
  } else if (disCode.substring(0, 2) == "13") {
    //河北
  } else if (disCode.substring(0, 4).match("4403")) {
    //深圳
  } else if (disCode.substring(0, 2).substring(0, 2) == "54") {
    //西藏
  } else if (disCode.substring(0, 2).substring(0, 2) == "34") {
    //安徽
  } else if (disCode.substring(0, 2).substring(0, 2) == "63") {
    //青海
  } else if (disCode.substring(0, 2).match("46")) {
    //海南
  } else if (disCode.substring(0, 2).match("53")) {
    //云南
  } else if (disCode.substring(0, 2).match("21")) {
    //辽宁
  } else if (disCode.substring(0, 2).match("50")) {
    //重庆
  } else if (disCode.substring(0, 2).match("42")) {
    //湖北
  } else if (disCode.substring(0, 2).match("44")) {
    //广东
  } else if (disCode.substring(0, 2).match("43")) {
    //湖南
  }
  return data;
}
export function disCodeUserPass(disCode, loginMethod) {
  let userPass = {
    jbr: false,
    jbrIdno: false,
    taxNo: true, //纳税人识别号
    loginMethod: "密码登录", //登录方式
    idNo: true, //登录用户名
    password: true, //税务密码
    zzrxm: false, //办税人姓名
    zzridno: false, //办税人身份号
    zzrmn: false, //办税人密码
    personalLoginType: true, //个税登良方式
    personalIdno: true, //个税实名登录账号
    personalTaxPwd: true, //个税密码
    xzsf: false, //选择身份
    idNoText: "身份证号码",
    passwordText: "密码",
    zzrxmText: "",
    zzridnoText: "",
    zzridnoTextLabel: "办税人/自然人身份证号",
    zzrmnText: "",
  };
  if (disCode.substring(0, 4) == "3702") {
    //青岛
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 4) == "3502") {
    //厦门
    let loginType = "新版登录";
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 4) == "2102") {
    //大连 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "代理登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 4) == "3302") {
    //宁波 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2) == "45") {
    //广西 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "64") {
    //宁夏 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2) == "65") {
    //新疆 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "22") {
    //吉林 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "23") {
    //黑龙江 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2) == "33") {
    //浙江 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "企业登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "办税人员姓名",
        zzridnoText: "办税人员身份证号",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "自然人用户登录密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人手机号/身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "35") {
    //福建 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2) == "36") {
    //江西 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "代理登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "62") {
    //甘肃 todo
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "37") {
    //山东
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    // if (loginType == "密码登录") {
    //   userPass = {
    //     taxNo: true, //纳税人识别号
    //     loginMethod: loginType, //登录方式
    //     loginMethodOption: ["密码登录", "新版登录"],
    //     loginMethodDisabled: true,
    //     idNo: true, //登录用户名
    //     password: false, //税务密码
    //     zzrxm: false, //办税人姓名
    //     zzridno: true, //办税人身份号
    //     zzrmn: true, //办税人密码
    //     personalLoginType: true, //个税登良方式
    //     personalIdno: true, //个税实名登录账号
    //     personalTaxPwd: true, //个税密码
    //     xzsf: false, //选择身份
    //     idNoText: "社会信用代码/识别号",
    //     passwordText: "",
    //     zzrxmText: "",
    //     zzridnoText: "手机号/身份证/操作员代码",
    //     zzridnoTextLabel: "办税人/自然人身份证号",

    //     zzrmnText: "密码（法定代表人、财务负责人、办税人员）",
    //     idNoLabel: "登录用户名",
    //     passwordLabel: "税务密码",
    //     zzrmnLabel: "办税人/自然人密码",
    //   };
    // } else {
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
    // }
  } else if (disCode.substring(0, 4).match("3100")) {
    //上海
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "社会信用代码/识别号",
      passwordText: "",
      zzrxmText: "",
      zzridnoText: "手机号/身份证/操作员代码",
      zzridnoTextLabel: "办税人/自然人身份证号",

      zzrmnText: "密码",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "自然人密码:",
    };
    // }
  } else if (disCode.substring(0, 2) == "15") {
    //内蒙
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
    // }
  } else if (disCode.substring(0, 2) == "11") {
    //北京
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "社会信用代码/识别号",
        passwordText: "",
        zzrxmText: "",
        zzridnoText: "手机号/身份证/操作员代码",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "自然人密码:",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "41") {
    //河南
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "短信登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["短信登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["短信登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["短信登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "32") {
    //江苏
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "用户名登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "政务网登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "纳税人识别号/社会信用代码/用户名/手机号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "办税人/自然人密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "政务网登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "政务网登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "政务网登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "用户名",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "请输入江苏电子税务局的密码",
        idNoLabel: "登录用户名",
        passwordLabel: "密码",
        zzrmnLabel: "税务局密码",
      };
    } else if (loginType == "代理登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "政务网登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "14") {
    //山西
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "51") {
    //四川
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "政务网登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "居民身份证号码/手机号码/用户名",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "政务网登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "政务网登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "社会信用代码/手机号",
        passwordText: "登录密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "政务网密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "政务网登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号:",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "12") {
    //天津
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: true, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "居民身份证号码/手机号码/用户名",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人手机号/身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2) == "61") {
    //陕西
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "代理登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "52") {
    //贵州
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "旧版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["旧版登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "企业账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["旧版登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["旧版登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2) == "13") {
    //河北
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 4).match("4403")) {
    //深圳
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "企业登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "纳税人识别号/统一社会信用代码",
        passwordText: "企业登录密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).substring(0, 2) == "54") {
    //西藏
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "普通登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["普通登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["普通登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "社会信用代码/识别号",
        passwordText: "",
        zzrxmText: "",
        zzridnoText: "手机号/身份证/操作员代码",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "自然人密码:",
      };
    }
  } else if (disCode.substring(0, 2).substring(0, 2) == "34") {
    //安徽
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "纳税人识别号/社会征信码",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).substring(0, 2) == "63") {
    //青海
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2).match("46")) {
    //海南
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "企业登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "办税人员姓名",
        zzridnoText: "办税人员身份证号",
        zzridnoTextLabel: "办税人/自然人身份证号",

        zzrmnText: "自然人用户登录密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["企业登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).match("53")) {
    //云南
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;

    if (loginType == "用户名登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "办税人员姓名",
        zzridnoText: "办税人员身份证号",
        zzridnoTextLabel: "办税人/自然人身份证号",

        zzrmnText: "自然人用户登录密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "办税人员姓名",

        zzridnoText: "办税人员身份证号",
        zzridnoTextLabel: "办税人/自然人身份证号",

        zzrmnText: "自然人用户登录密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["用户名登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).match("21")) {
    //辽宁
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "普通登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["普通登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: true, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/纳税人识别号",
        passwordText: "企业登录密码",
        zzrxmText: "办税人员姓名",
        zzridnoText: "办税人员身份证号",
        zzridnoTextLabel: "办税人/自然人身份证号",

        zzrmnText: "自然人用户登录密码",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["普通登录", "新版登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).match("50")) {
    //重庆
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: false, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人/自然人身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2).match("42")) {
    //湖北
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "自然人用户登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["自然人用户登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: true, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: false, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: true, //选择身份
        idNoText: "社会信用代码/用户名",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["自然人用户登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: true, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人手机号/身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["自然人用户登录", "新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  } else if (disCode.substring(0, 2).match("44")) {
    //广东
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    userPass = {
      taxNo: true, //纳税人识别号
      loginMethod: loginType, //登录方式
      loginMethodOption: ["新版登录"],
      loginMethodDisabled: true,
      idNo: false, //登录用户名
      password: false, //税务密码
      zzrxm: false, //办税人姓名
      zzridno: true, //办税人身份号
      zzrmn: true, //办税人密码
      personalLoginType: true, //个税登良方式
      personalIdno: true, //个税实名登录账号
      personalTaxPwd: true, //个税密码
      xzsf: false, //选择身份
      idNoText: "账号",
      passwordText: "密码",
      zzrxmText: "",
      zzridnoText: "",
      zzridnoTextLabel: "办税人手机号/身份证号",
      zzrmnText: "",
      idNoLabel: "登录用户名",
      passwordLabel: "税务密码",
      zzrmnLabel: "办税人/自然人密码",
    };
  } else if (disCode.substring(0, 2).match("43")) {
    //湖南
    let loginType = "新版登录";
    loginType = loginMethod ? loginMethod : loginType;
    if (loginType == "新版登录") {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: false, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "账号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    } else {
      userPass = {
        taxNo: true, //纳税人识别号
        loginMethod: loginType, //登录方式
        loginMethodOption: ["新版登录", "代理登录"],
        loginMethodDisabled: true,
        idNo: true, //登录用户名
        password: false, //税务密码
        zzrxm: false, //办税人姓名
        zzridno: false, //办税人身份号
        zzrmn: true, //办税人密码
        personalLoginType: true, //个税登良方式
        personalIdno: true, //个税实名登录账号
        personalTaxPwd: true, //个税密码
        xzsf: false, //选择身份
        idNoText: "统一社会信用代码/纳税人识别号",
        passwordText: "密码",
        zzrxmText: "",
        zzridnoText: "",
        zzridnoTextLabel: "办税人/自然人身份证号",
        zzrmnText: "",
        idNoLabel: "登录用户名",
        passwordLabel: "税务密码",
        zzrmnLabel: "办税人/自然人密码",
      };
    }
  }

  return userPass;
}
